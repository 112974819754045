import * as React from "react";
import { useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Webcam from "react-webcam";
import { Alert, Paper, Stack } from "@mui/material";
import * as mpFaceMesh from "@mediapipe/face_mesh";
import { Camera } from "@mediapipe/camera_utils";
import * as drawingUtils from "@mediapipe/drawing_utils";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

import { authenticateUserFace } from "../../modules/redux/features/faceAuth/actions/authenticateUserFace";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hooks";
import { selectFaceAuth } from "../../modules/redux/features/faceAuth";

const videoConstraints = {
  width: 512,
  height: 512,
  facingMode: "user",
};

/**
 * Solution options.
 */
const solutionOptions = {
  selfieMode: true,
  enableFaceGeometry: true,
  maxNumFaces: 1,
  refineLandmarks: false,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
};

const faceMeshConfig = {
  locateFile: (file: string) => {
    return (
      `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@` +
      `${mpFaceMesh.VERSION}/${file}`
    );
  },
};

interface IFacialLoginPageProps {}

function FacialLoginPage(props: IFacialLoginPageProps) {
  const webcamRef = React.useRef<Webcam>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [isFaceMeshLoading, setFaceMeshLoading] = React.useState<boolean>(true);
  const [currentScreenShoot, setCurrentScreenShoot] = React.useState<
    string | null
  >();

  let [searchParams, setSearchParams] = useSearchParams();

  const actionDispatch = useAppDispatch();
  const reducerState = useAppSelector(selectFaceAuth);

  const onResults = React.useCallback(
    (results: mpFaceMesh.Results) => {
      if (canvasRef !== null && canvasRef.current != null) {
        const canvasCtx = canvasRef.current.getContext("2d");
        if (canvasCtx !== null) {
          setFaceMeshLoading(false);

          // Draw the overlays.
          canvasCtx.save();
          canvasCtx.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );

          canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          if (
            results.multiFaceLandmarks &&
            results.multiFaceLandmarks.length > 0
          ) {
            setCurrentScreenShoot(
              (results.image as any).toDataURL("image/jpeg", 1.0)
            );

            for (const landmarks of results.multiFaceLandmarks) {
              drawingUtils.drawConnectors(
                canvasCtx,
                landmarks,
                mpFaceMesh.FACEMESH_TESSELATION,
                {
                  color: reducerState.isAuthenticated ? "#30FF30" : "#FF3030",
                  lineWidth: 0.1,
                }
              );
              drawingUtils.drawConnectors(
                canvasCtx,
                landmarks,
                mpFaceMesh.FACEMESH_FACE_OVAL,
                {
                  color: reducerState.isAuthenticated ? "#30FF30" : "#FF3030",
                  lineWidth: 2,
                }
              );

              if (solutionOptions.refineLandmarks) {
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_RIGHT_IRIS,
                  { color: "#FF3030" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LEFT_IRIS,
                  { color: "#30FF30" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LIPS,
                  { color: "#E0E0E0" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_RIGHT_EYE,
                  { color: "#FF3030" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_RIGHT_EYEBROW,
                  { color: "#FF3030" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LEFT_EYE,
                  { color: "#30FF30" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LEFT_EYEBROW,
                  { color: "#30FF30" }
                );
              }
            }
          } else {
            setCurrentScreenShoot(null);
          }
          canvasCtx.restore();
        }
      }
    },
    [canvasRef, reducerState.isAuthenticated]
  );

  // Instantiate facemesh model
  const faceMesh = React.useMemo(() => {
    const _faceMesh = new mpFaceMesh.FaceMesh(faceMeshConfig);
    _faceMesh.setOptions(solutionOptions);
    _faceMesh.onResults(onResults);
    return _faceMesh;
  }, [onResults]);

  // Run
  React.useEffect(() => {
    const videoRef = webcamRef.current?.video;

    const camera = new Camera(videoRef as any, {
      onFrame: async () => {
        await faceMesh.send({ image: videoRef as any });
      },
      width: 512,
      height: 512,
    });

    camera.start();
  }, [webcamRef, faceMesh]);

  React.useEffect(() => {
    if (
      !reducerState.isAuthenticating &&
      searchParams.get("token") &&
      currentScreenShoot &&
      !reducerState.isAuthenticated
    ) {
      actionDispatch(
        authenticateUserFace({
          userPic: currentScreenShoot,
          tokenUrl: searchParams.get("token") ?? "",
        })
      );
    }
  }, [
    reducerState.isAuthenticating,
    searchParams,
    currentScreenShoot,
    reducerState.isAuthenticated,
  ]);

  // Redirect to the authencation process
  React.useEffect(() => {
    if (reducerState.isAuthenticated && reducerState.redirectUrl) {
      setTimeout(() => {
        document.location.href = reducerState.redirectUrl ?? "";
      }, 2000);
    }
  }, [reducerState.isAuthenticated, reducerState.redirectUrl]);

  return (
    <Stack direction="column" alignItems="center" spacing={0} sx={{ mt: 4 }}>
      {currentScreenShoot &&
        !reducerState.isAuthenticated &&
        !reducerState.isAuthenticating && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Utilisateur non reconnu
          </Alert>
        )}
      {reducerState.isAuthenticated && !reducerState.isAuthenticating && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Authentifié avec succès
        </Alert>
      )}
      {reducerState.isAuthenticating && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Vérification en cours
        </Alert>
      )}
      {!currentScreenShoot &&
        !reducerState.isAuthenticated &&
        !reducerState.isAuthenticating && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Placez-vous devant la webcam
          </Alert>
        )}
      <Paper
        variant="outlined"
        sx={{
          mb: 2,
          pt: 2,
          pl: 2,
          width: 528,
          height: 528,
        }}
      >
        <Box component={"div"} style={{ position: "relative" }}>
          <Webcam
            id="input_video"
            audio={false}
            height={512}
            width={512}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <canvas
            id="output_canvas"
            ref={canvasRef}
            width="512px"
            height="512px"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></canvas>
          {isFaceMeshLoading && (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                left: 0,
                width: 512,
                height: 512,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {reducerState.isAuthenticating && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: 0,
                left: 0,
                width: 512,
                height: 512,
                justifyContent: "space-between",
              }}
              component="div"
            >
              <LinearProgress color="success" />
              <LinearProgress color="error" />
            </Box>
          )}
        </Box>
      </Paper>
    </Stack>
  );
}

export default FacialLoginPage;
