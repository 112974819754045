import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import ObjectId from "bson-objectid";

import KeycloakInstance from "../../../../KeycloakAuthProvider/KeycloakInstance";

import {
  IGenerateSignedUrlResponse,
  SignedUrlCommand,
} from "../../../../services/objects/types";
import ReduxActions from "../../../actions";
import { generateObjSignedUrl } from "../../../../services/objects";
import { updateUploadState, storeNewPhoto } from "../reducer";
import { uploadObjToStorage } from "../../../../services/objects/uploadObjToStorage";
import { convertB64ObjToBuffer } from "../../../../services/objects/convertB64ObjToBuffer";
import { addNewIdPhoto } from "../../../../services/idphotos";
import { IIdPhotoItem } from "../../../../services/idphotos/types";

export const uploadNewIdPhoto = (imageData: string) => {
  return {
    type: ReduxActions.UPLOAD_ID_PHOTO,
    payload: imageData,
  };
};

export function* uploadNewIdPhotoAction(action: PayloadAction<string>) {
  const objectKey = `${KeycloakInstance.subject}/id-photo-${ObjectId()}.jpeg`;

  // Set loading state to true
  yield put(updateUploadState(true));

  // Generate S3 Signed URL for upload
  const urlResponse: AxiosResponse<IGenerateSignedUrlResponse> = yield call(
    generateObjSignedUrl,
    {
      command: SignedUrlCommand.PUT,
      objectKey,
    }
  );

  // Convert B64 image to buffer
  const buffResponse: ArrayBuffer = yield call(
    convertB64ObjToBuffer,
    action.payload
  );

  // Upload the photo to S3
  yield call(uploadObjToStorage, {
    signedUrl: urlResponse.data.signedUrl,
    object: buffResponse,
  });

  // Add the metadata of the photo to the user's database
  const idResponse: AxiosResponse<IIdPhotoItem> = yield call(addNewIdPhoto, {
    objectKey: objectKey,
    objectType: "image/jpeg",
  });

  // Add the new uploaded file to the store
  yield put(storeNewPhoto(idResponse.data));

  // Set loading state to false
  yield put(updateUploadState(false));
}
