import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import ReduxActions from "../../../actions";
import { updateFetchState, loadPhotoList } from "../reducer";
import { fetchIdPhotos } from "../../../../services/idphotos/fetchIdPhotos";
import { IIdPhotoItem } from "../../../../services/idphotos/types";

export const fetchUserIdPhotos = () => {
  return {
    type: ReduxActions.FETCH_ID_PHOTOS,
  };
};

export function* fetchUserIdPhotosAction(action: PayloadAction) {
  // Set loading state to true
  yield put(updateFetchState(true));

  // Fetch the id photos of the current user
  const fetchResponse: AxiosResponse<IIdPhotoItem[]> = yield call(
    fetchIdPhotos
  );

  // Add the fetched photos to the store
  yield put(loadPhotoList(fetchResponse.data));

  // Set loading state to false
  yield put(updateFetchState(false));
}
