import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import ReduxActions from "../../../actions";
import { deleteIdPhoto } from "../../../../services/idphotos/deleteIdPhoto";
import { updateFetchState, deletePhoto } from "..";

export const deleteUserIdPhoto = (id: number) => {
  return {
    type: ReduxActions.DELETE_ID_PHOTOS,
    payload: id,
  };
};

export function* deleteUserIdPhotoAction(action: PayloadAction<number>) {
  // Set loading state to true
  // yield put(updateFetchState(true));

  // Delete the photo from the database
  yield call(deleteIdPhoto, action.payload);

  // Delete the photo from the store
  yield put(deletePhoto(action.payload));

  // Set loading state to false
  // yield put(updateFetchState(false));
}
