import React from "react";
import PhotosSlide from "./PhotosSlide";
import LiveCamRegistration from "./LiveCamRegistration";

const IdPhotosPage = () => {
  return (
    <>
      <LiveCamRegistration />
      <PhotosSlide />
    </>
  );
};

export default IdPhotosPage;
