import {
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import Skeleton from "@mui/material/Skeleton";
import AppHeader from "./AppHeader";
import { AppDrawer } from "../AppLayout/AppLayout";

const AppLayoutSkeleton: FC = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <AppHeader />
      <AppDrawer variant="permanent" open>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <Skeleton variant="circular" height={"40"} width={"40"} />
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton>
            <ListItemIcon>
              <Skeleton variant="circular" width={24} height={24} />
            </ListItemIcon>
            <Skeleton variant="rectangular" width={96} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <Skeleton variant="circular" width={24} height={24} />
            </ListItemIcon>
            <Skeleton variant="rectangular" width={64} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <Skeleton variant="circular" width={24} height={24} />
            </ListItemIcon>
            <Skeleton variant="rectangular" width={86} />
          </ListItemButton>
        </List>
      </AppDrawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}></Container>
      </Box>
    </Box>
  );
};

export default AppLayoutSkeleton;
