import axios from "axios";
import { IAM_SERVER_URL } from "../../config";
import KeycloakInstance from "../KeycloakAuthProvider/KeycloakInstance";

export const IamApi = axios.create({
  baseURL: IAM_SERVER_URL,
});

IamApi.interceptors.request.use(
  async (req) => {
    const access_token = KeycloakInstance.token;

    // console.log({
    //   METHOD: req.method,
    //   baseURL: req.baseURL,
    //   url: req.url,
    //   access_token,
    //   params: req.params,
    //   data: req.data ? JSON.stringify(req.data) : "",
    // });

    if (req.headers === undefined) {
      req.headers = {};
    }
    req.headers.Authorization = access_token ? `Bearer ${access_token}` : "";
    req.headers.Accept = "*/*";
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default IamApi;
