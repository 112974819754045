export enum SignedUrlCommand {
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
}

export interface IGenerateSignedUrlPayload {
  objectKey: string;
  command: SignedUrlCommand;
}

export interface IGenerateSignedUrlResponse {
  signedUrl: string;
  expiresIn: number;
}

export interface IUploadObjToStoragePayload {
  signedUrl: string;
  object: any;
}
