import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import ReduxActions from "../../../actions";
import {
  updateAuthenticatedState,
  updateAuthenticationState,
  updateRedirectUrlState,
} from "../reducer";
import {
  IAuthenticateFacePayload,
  IAuthenticateFaceResponse,
} from "../../../../services/authFace/types";
import { authenticateFace } from "../../../../services/authFace";

export const authenticateUserFace = (payload: IAuthenticateFacePayload) => {
  return {
    type: ReduxActions.AUTHENTICATE_USER_FACE,
    payload,
  };
};

export function* authenticateUserFaceAction(
  action: PayloadAction<IAuthenticateFacePayload>
) {
  // Set loading state to true
  yield put(updateAuthenticationState(true));

  // Is authenticated
  yield put(updateAuthenticatedState(false));

  // Update redirection URL
  yield put(updateRedirectUrlState(null));

  try {
    // Authenticate user face
    const authResponse: AxiosResponse<IAuthenticateFaceResponse> = yield call(
      authenticateFace,
      action.payload
    );
    // Is authenticated
    yield put(updateAuthenticatedState(authResponse.data.authenticated));

    // Update redirection URL
    yield put(updateRedirectUrlState(authResponse.data.token_url));
  } catch (error) {
    // Pass the error
  }

  // Set loading state to false
  yield put(updateAuthenticationState(false));
}
