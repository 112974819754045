import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import RequireAuth from "./components/RequireAuth";
import HomePage from "./pages/HomePage";
import AppLayout from "./components/AppLayout";
import IdPhotosPage from "./pages/IdPhotosPage";
import store from "./modules/redux/store";
import FacialLoginPage from "./pages/FacialLoginPage";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/idphotos"
              element={
                <RequireAuth>
                  <IdPhotosPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/facial/auth" element={<FacialLoginPage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
