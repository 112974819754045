import axios from "axios";
import { IUploadObjToStoragePayload } from "./types";

export const uploadObjToStorage = (inputData: IUploadObjToStoragePayload) => {
  return axios.put(inputData.signedUrl, inputData.object, {
    headers: {
      "Content-Type": "image/jpeg",
    },
  });
};
