import React, { FC } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Skeleton from "@mui/material/Skeleton";
import KeycloakInstance from "./KeycloakInstance";
import LoadingComponent from "../../components/LoadingComponent";
import { Box, Stack } from "@mui/material";
import AppLayoutSkeleton from "../../components/AppLayoutSkeleton";
// import KeycloakAuthContext from "./KeycloakAuthContext";

interface IProps {
  children: React.ReactNode;
}

const KeycloakAuthProvider: FC<IProps> = ({ children }) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const handleToken = (tokens: unknown) => {
    console.log("onKeycloakTokens", tokens);
  };

  return (
    <ReactKeycloakProvider
      authClient={KeycloakInstance}
      autoRefreshToken
      initOptions={{
        onLoad: "check-sso",
        pkceMethod: "S256",
        silentCheckSsoFallback: false,
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      }}
      onEvent={(clientEvent, eventError) => {
        if (["onReady", "onInitError", "onAuthSuccess"].includes(clientEvent)) {
          setLoading(false);
        }
        console.log("onKeycloakEvent", clientEvent, eventError);
      }}
      onTokens={handleToken}
      LoadingComponent={
        <AppLayoutSkeleton />
        // <Box>
        //   <Stack spacing={1} margin={1} direction="column">
        //     <Stack spacing={1} direction="row">
        //       <Skeleton variant="rectangular" height={64} width={"20%"} />
        //       <Skeleton variant="rectangular" height={64} width={"80%"} />
        //     </Stack>
        //     <Stack spacing={1} direction="row">
        //       <Skeleton variant="rectangular" height={256} width={"20%"} />
        //       <Skeleton variant="circular" width={40} height={40} />
        //       <Skeleton variant="rectangular" width={210} height={118} />
        //     </Stack>
        //   </Stack>
        // </Box>
      }
      isLoadingCheck={(kcClient) => {
        return isLoading;
      }}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default KeycloakAuthProvider;
