import React, { FC } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Skeleton, Stack } from "@mui/material";
import { AppBar } from "../../AppLayout/AppHeader/AppHeader";

const AppHeader: FC = () => {
  return (
    <AppBar color="default" position="absolute" elevation={0} open>
      <Toolbar>
        <Stack spacing={0.5} direction="column">
          <Skeleton variant="rectangular" width={24} height={4} />
          <Skeleton variant="rectangular" width={24} height={4} />
          <Skeleton variant="rectangular" width={24} height={4} />
        </Stack>
        <Box sx={{ width: 24 }} />
        <Typography component="div" variant="body1">
          <Skeleton animation="pulse" width={256} />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Skeleton variant="circular" width={40} height={40} />
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
