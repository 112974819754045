import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";
import Webcam from "react-webcam";
import { Paper, Stack } from "@mui/material";
import * as mpFaceMesh from "@mediapipe/face_mesh";
import { Camera } from "@mediapipe/camera_utils";
import * as drawingUtils from "@mediapipe/drawing_utils";
import { Box } from "@mui/system";

import { uploadNewIdPhoto } from "../../modules/redux/features/idphotos/actions/uploadNewIdPhoto";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hooks";
import { selectIdPhotos } from "../../modules/redux/features/idphotos";

const videoConstraints = {
  width: 512,
  height: 512,
  facingMode: "user",
};

/**
 * Solution options.
 */
const solutionOptions = {
  selfieMode: true,
  enableFaceGeometry: true,
  maxNumFaces: 1,
  refineLandmarks: false,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
};

const faceMeshConfig = {
  locateFile: (file: string) => {
    return (
      `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@` +
      `${mpFaceMesh.VERSION}/${file}`
    );
  },
};

interface ILiveCamRegistrationProps {}

function LiveCamRegistration(props: ILiveCamRegistrationProps) {
  const webcamRef = React.useRef<Webcam>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [isFaceMeshLoading, setFaceMeshLoading] = React.useState<boolean>(true);

  const actionDispatch = useAppDispatch();
  const reducerState = useAppSelector(selectIdPhotos);

  const capturePhoto = React.useCallback(() => {
    if (webcamRef.current !== null) {
      const imageBase64 = webcamRef.current.getScreenshot();
      if (imageBase64) {
        actionDispatch(uploadNewIdPhoto(imageBase64));
      }
    }
  }, [webcamRef.current]);

  const onResults = React.useCallback(
    (results: mpFaceMesh.Results) => {
      if (canvasRef !== null && canvasRef.current != null) {
        const canvasCtx = canvasRef.current.getContext("2d");

        if (canvasCtx !== null) {
          setFaceMeshLoading(false);

          // Draw the overlays.
          canvasCtx.save();
          canvasCtx.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          if (results.multiFaceLandmarks) {
            for (const landmarks of results.multiFaceLandmarks) {
              drawingUtils.drawConnectors(
                canvasCtx,
                landmarks,
                mpFaceMesh.FACEMESH_TESSELATION,
                { color: "#30FF30", lineWidth: 0.1 }
              );
              drawingUtils.drawConnectors(
                canvasCtx,
                landmarks,
                mpFaceMesh.FACEMESH_FACE_OVAL,
                { color: "#30FF30", lineWidth: 2 }
              );

              if (solutionOptions.refineLandmarks) {
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_RIGHT_IRIS,
                  { color: "#FF3030" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LEFT_IRIS,
                  { color: "#30FF30" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LIPS,
                  { color: "#E0E0E0" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_RIGHT_EYE,
                  { color: "#FF3030" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_RIGHT_EYEBROW,
                  { color: "#FF3030" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LEFT_EYE,
                  { color: "#30FF30" }
                );
                drawingUtils.drawConnectors(
                  canvasCtx,
                  landmarks,
                  mpFaceMesh.FACEMESH_LEFT_EYEBROW,
                  { color: "#30FF30" }
                );
              }
            }
          }
          canvasCtx.restore();
        }
      }
    },
    [canvasRef]
  );

  // Instantiate facemesh model
  const faceMesh = React.useMemo(() => {
    const _faceMesh = new mpFaceMesh.FaceMesh(faceMeshConfig);
    _faceMesh.setOptions(solutionOptions);
    _faceMesh.onResults(onResults);
    return _faceMesh;
  }, [onResults]);

  // Run
  React.useEffect(() => {
    const videoRef = webcamRef.current?.video;

    const camera = new Camera(videoRef as any, {
      onFrame: async () => {
        await faceMesh.send({ image: videoRef as any });
      },
      width: 512,
      height: 512,
    });

    camera.start();
  }, [webcamRef, faceMesh]);

  return (
    <Stack direction="column" alignItems="center" spacing={0}>
      <Paper
        variant="outlined"
        sx={{
          mb: 2,
          p: 1,
          width: 528,
          height: 528,
        }}
      >
        <Box component={"div"} style={{ position: "relative" }}>
          <Webcam
            id="input_video"
            audio={false}
            height={512}
            width={512}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1.0}
            videoConstraints={videoConstraints}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <canvas
            id="output_canvas"
            ref={canvasRef}
            width="512px"
            height="512px"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          ></canvas>
          {isFaceMeshLoading && (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                left: 0,
                width: 512,
                height: 512,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Paper>
      <LoadingButton
        disableElevation
        variant="contained"
        onClick={capturePhoto}
        startIcon={<PhotoCameraIcon />}
        sx={{ mb: 2 }}
        loading={reducerState.isUploading}
        loadingPosition="start"
        disabled={reducerState.isFetching}
      >
        Capture photo
      </LoadingButton>
    </Stack>
  );
}

export default LiveCamRegistration;
