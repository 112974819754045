import axios from "axios";
import { IAuthenticateFacePayload, IAuthenticateFaceResponse } from "./types";
import { FACEMATCHER_SERVER_URL } from "../../../config";

export const authenticateFace = (inputData: IAuthenticateFacePayload) => {
  return axios.post<IAuthenticateFaceResponse>(
    `${FACEMATCHER_SERVER_URL}df/authenticate`,
    inputData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
