import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIdPhotoItem } from "../../../services/idphotos/types";
import { RootState } from "../../store";

interface IdPhotosState {
  isUploading: boolean;
  isFetching: boolean;
  photos: IIdPhotoItem[];
}

const initialState: IdPhotosState = {
  photos: [],
  isUploading: false,
  isFetching: false,
};

export const idPhotoSlice = createSlice({
  name: "idphotos",
  initialState,
  reducers: {
    updateUploadState(state, action: PayloadAction<boolean>) {
      state.isUploading = action.payload;
    },
    updateFetchState(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    loadPhotoList(state, action: PayloadAction<IIdPhotoItem[]>) {
      state.photos = action.payload;
    },
    storeNewPhoto(state, action: PayloadAction<IIdPhotoItem>) {
      state.photos = [...state.photos, action.payload];
    },
    deletePhoto(state, action: PayloadAction<number>) {
      state.photos = state.photos.filter(
        (photoItem) => photoItem.id !== action.payload
      );
    },
  },
});

export const {
  storeNewPhoto,
  deletePhoto,
  loadPhotoList,
  updateUploadState,
  updateFetchState,
} = idPhotoSlice.actions;
export default idPhotoSlice.reducer;

export const selectIdPhotos = (state: RootState) => state.idphotos;
