export const KC_CLIENT_ID: string =
  process.env.REACT_APP_KC_CLIENT_ID ?? "iam-app-dev-local";
export const KC_REALM: string =
  process.env.REACT_APP_KC_REALM ?? "prodigy-demo";
export const KC_SERVER_URL: string =
  process.env.REACT_APP_KC_SERVER_URL ?? "https://staging.sso.gov.mg/";
export const IAM_SERVER_URL: string =
  process.env.REACT_APP_IAM_SERVER_URL ?? "https://staging.api.iam.gov.mg/";

// export const FACEMATCHER_SERVER_URL: string =
//   process.env.REACT_APP_FACEMATCHER_SERVER_URL ?? "http://127.0.0.1:8888/";

export const FACEMATCHER_SERVER_URL: string =
  process.env.REACT_APP_FACEMATCHER_SERVER_URL ?? "https://stg.fm.iam.gov.mg/";
