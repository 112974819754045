import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface FaceAuthState {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  redirectUrl?: string | null;
}

const initialState: FaceAuthState = {
  isAuthenticating: false,
  isAuthenticated: false,
  redirectUrl: null,
};

export const faceAuthSlice = createSlice({
  name: "faceAuth",
  initialState,
  reducers: {
    updateAuthenticationState(state, action: PayloadAction<boolean>) {
      state.isAuthenticating = action.payload;
    },
    updateAuthenticatedState(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    updateRedirectUrlState(
      state,
      action: PayloadAction<string | null | undefined>
    ) {
      state.redirectUrl = action.payload;
    },
  },
});

export const {
  updateAuthenticationState,
  updateAuthenticatedState,
  updateRedirectUrlState,
} = faceAuthSlice.actions;
export default faceAuthSlice.reducer;

export const selectFaceAuth = (state: RootState) => state.faceAuth;
