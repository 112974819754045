import * as React from "react";
import Box from "@mui/material/Box";
import { Swiper, SwiperSlide } from "swiper/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pagination, Navigation } from "swiper";
import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Paper,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

// Import Swiper styles
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";
import { fetchUserIdPhotos } from "../../modules/redux/features/idphotos/actions/fetchUserIdPhotos";
import { deleteUserIdPhoto } from "../../modules/redux/features/idphotos/actions/deleteUserIdPhoto";
import { useAppDispatch, useAppSelector } from "../../modules/redux/hooks";
import { selectIdPhotos } from "../../modules/redux/features/idphotos";

interface IPhotosSlideProps {}

function PhotosSlide(props: IPhotosSlideProps) {
  const actionDispatch = useAppDispatch();
  const reducerState = useAppSelector(selectIdPhotos);

  React.useEffect(() => {
    actionDispatch(fetchUserIdPhotos());
  }, []);

  return (
    <Paper variant="outlined">
      <Box component={"div"} sx={{ p: 1, maxHeight: 256 }}>
        <Swiper
          spaceBetween={8}
          slidesPerView={6}
          pagination={true}
          navigation={true}
          modules={[Navigation, Pagination]}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {/* {reducerState.isFetching &&
            reducerState.photos.map((photoItem, index) => (
              <SwiperSlide key={`photo-${photoItem.id}`}>
                <Box
                  component="img"
                  sx={{
                    height: "100%",
                    display: "block",
                    width: "100%",
                    maxWidth: 400,
                    maxHeight: 196,
                    objectFit: "scale-down",
                    overflow: "hidden",
                  }}
                  src={photoItem.getUrl}
                  alt={`User photo ${index}`}
                />
              </SwiperSlide>
            ))} */}

          {!reducerState.isFetching &&
            reducerState.photos.map((photoItem, index) => (
              <SwiperSlide key={`photo-${photoItem.id}`}>
                <ImageListItem>
                  <img src={photoItem.getUrl} loading="lazy" />
                  <ImageListItemBar
                    position="top"
                    title={index + 1}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        onClick={() =>
                          actionDispatch(deleteUserIdPhoto(photoItem.id))
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>
    </Paper>
  );
}

export default PhotosSlide;
