import { takeEvery, takeLatest } from "redux-saga/effects";
import ReduxActions from "../../actions";
import { uploadNewIdPhotoAction } from "./actions/uploadNewIdPhoto";
import { fetchUserIdPhotosAction } from "./actions/fetchUserIdPhotos";
import { deleteUserIdPhotoAction } from "./actions/deleteUserIdPhoto";

const watchers = [
  takeEvery(ReduxActions.UPLOAD_ID_PHOTO, uploadNewIdPhotoAction),
  takeLatest(ReduxActions.FETCH_ID_PHOTOS, fetchUserIdPhotosAction),
  takeEvery(ReduxActions.DELETE_ID_PHOTOS, deleteUserIdPhotoAction),
];

export default watchers;
