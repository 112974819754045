import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const HomePage = () => {
  return (
    <Paper variant="outlined" sx={{ p: 8 }}>
      <Box>
        <Typography variant="h6">
          Bienvenue sur la plateforme IAM de l'UGD.
        </Typography>
      </Box>
    </Paper>
  );
};
export default HomePage;
