import Keycloak from "keycloak-js";
import { KC_CLIENT_ID, KC_REALM, KC_SERVER_URL } from "../../config";

const KeycloakInstance = Keycloak({
  url: KC_SERVER_URL,
  realm: KC_REALM,
  clientId: KC_CLIENT_ID,
});

export default KeycloakInstance;
