import React, { FC } from "react";

import { useKeycloak } from "@react-keycloak/web";
interface IProps {
  children: React.ReactElement;
}

const RequireAuth: FC<IProps> = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  return isLoggedIn ? children : null;
};

export default RequireAuth;
