import React, { FC, useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { AccountCircle } from "@mui/icons-material";
import { Divider, IconButton, Popover, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import { Box } from "@mui/system";

const HeaderProfile: FC = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const { keycloak, initialized } = useKeycloak();

  const handleLogin = useCallback(() => {
    keycloak?.login({
      scope: "openid email profile roles phone",
    });
  }, [keycloak]);

  const handleLogout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const handleToggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      {keycloak.authenticated ? (
        <>
          {" "}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleToggleMenu}
            ref={anchorRef}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Popover
            id={"profile-popover"}
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleToggleMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            elevation={3}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  component="span"
                  color="textPrimary"
                >
                  {keycloak.tokenParsed?.given_name}{" "}
                  {keycloak.tokenParsed?.family_name}
                </Typography>
                <br />
                <Typography
                  variant="body1"
                  component="span"
                  color="textSecondary"
                >
                  @{keycloak.tokenParsed?.preferred_username}{" "}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                startIcon={<ExitToAppIcon fontSize="small" />}
                onClick={() => {
                  handleToggleMenu();
                  handleLogout();
                }}
              >
                Se déconnecter
              </Button>
            </Box>
          </Popover>
        </>
      ) : (
        <Button
          color="inherit"
          variant="outlined"
          startIcon={<LoginIcon fontSize="small" />}
          onClick={handleLogin}
        >
          Se connecter
        </Button>
      )}
    </>
  );
};
export default HeaderProfile;
